.rs-table-cell-header {
    &.text-center {
        .rs-table-cell-content {
            justify-content: center;
        }
    }

    .rs-table-cell {
        border-bottom: 1px solid #f6f6f6;
    }

    .rs-table-cell-content,
    .emplpoyeeNameCell {
        font-size: 16px;
        font-weight: 400;
        color: $text-grey;
        display: flex;
        align-items: center;
        overflow: unset;

        @media (max-width: 1366px) {
            font-size: 14px;
        }
    }
}

.font-weight-bold {
    &.rs-table-cell-header {
        .rs-table-cell-content {
            font-weight: bold;
            color: $theme-black;
        }
    }
}

.rs-table-cell-group-left-shadow,
.rs-table-cell-group-right-shadow,
.rs-table-cell-group-shadow {
    box-shadow: none;
}

.bgLightBlue {
    background-color: #f8fbfe;

    .rs-table-cell {
        background-color: #f8fbfe;
    }

}

.driveTimeTable {
    .table-content-editing {
        .rs-table-cell-content {
            padding: 2px 13px 3px 13px;

            .rs-input {
                border-radius: 8px;
                padding: 8px;
                text-align: center;
            }
        }
    }
}

.rs-input {
    &.error {
        border-color: red;

        &:hover {
            border-color: red;
        }
    }
}



.rs-table-row {
    overflow: unset !important;
}

.rs-table-cell-content {
    font-size: 14px;
    color: $theme-black;
    font-weight: 400;
    overflow: unset;

    @media (max-width: 1366px) {
        font-size: 12px;
    }
}

.rs-table-cell-header-icon-sort {
    color: $text-grey;
}

.rs-table-row-header {
    border-bottom: 1px solid #f6f6f6;
}

.rs-table-cell {
    border: 0;
    overflow: unset;
}

.rs-table-scrollbar-vertical {
    width: 4px;

    .rs-table-scrollbar-handle {
        width: 3px;
    }
}

/** Editing Cell */
.table-content-editing {
    .rs-table-cell-content {
        display: inline-flex;
        padding: 2px 13px 3px 0;

        // .rs-input {
        //     border-radius: 14px;
        //     padding: 14px 11px;


        //     &:focus,
        //     &:active {
        //         border-color: $theme-blue;
        //         box-shadow: unset;
        //         outline: unset;
        //     }

        // }
    }
}

/** Pagination */
.rs-pagination-btn {
    background-color: #F9FAFA;
    color: #7D8592;

    &.rs-pagination-btn-active {
        background-color: $theme-blue;
        color: white;

    }

    &.rs-pagination-btn {
        padding: 7px 11px;
        line-height: 1;
        width: 30px;
        height: 30px;
        margin: 0 5px;
        display: flex;
        align-items: center;
    }

    &.rs-pagination-btn-disabled {
        opacity: 0.5;
        background-color: #F9FAFA;
        color: #7D8592;
    }
}

.pagiantion-box {
    margin-top: 43px;
    margin-bottom: 30px;
}

/** Dotted Action */
.editBtn {
    display: inline-flex;

    button,
    .EditIcon,
    .TrashIcon {
        background: $light-grey;
        color: $theme-black;
        border: 0;
        transition: 0.5s ease;
        font-size: 12px;

        &:hover {
            background-color: $theme-blue;

            svg {
                path {
                    fill: white;
                }
            }
        }
    }

}

//** Employees Name array CSS on employess aniversary page */
.emplpoyeeNameCell {
    display: flex;
    align-items: center;

    .employeeMoreBtn {
        background: transparent;
        padding: 0;
        margin-left: 4px;
        line-height: 1;
        font-weight: 700;
        font-size: 14px;
        line-height: 1;
        color: $theme-blue;
        transition: 0.5s ease;

        @media (max-width: 1366px) {
            font-size: 12px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    p {
        padding-right: 2px;
        margin: 0;
        color: $theme-black;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        overflow: unset;

        @media (max-width: 1366px) {
            font-size: 12px;
        }
    }
}

/**Report mix table css*/
.reportMixTable {
    .rs-table-column-group-header {
        display: none;
    }

    .rs-table-cell-content {
        justify-content: center;
    }
}


.turnOverRow {
    &:last-child {
        .emplpoyeeNameCell {
            a {
                pointer-events: none;
                color: $theme-black;
            }
        }
    }
}

.scrollable-table {
    overflow-x: scroll;
}


.tableCheckbox {
    .form-check-input {
        width: 24px;
        height: 24px;

        @media (max-width: 1366px) {
            width: 20px;
            height: 20px;
        }
    }
}


.readMoreBtn {
    background: transparent;
    padding: 0;
    margin-left: 4px;
    line-height: 1;
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
    color: $theme-blue;
    transition: 0.5s ease;

    @media (max-width: 1366px) {
        font-size: 12px;
    }

    &:hover {
        text-decoration: underline;
    }
}


.salesPercent {
    span {
        font-size: 12px;
    }
}


.countColumn {
    .greenColor {
        color: #E62E2E;
    }
}

.rs-table-body-info-wheel-area,
.rs-table-body-wheel-area {
    transition: unset !important;
}

//** All Table Responsive*/