.breadcrumb {
    margin: 0;
    margin-bottom: 7px;

    .breadcrumb-item {
        font-size: 16px;

        @media (max-width: 1366px) {
            font-size: 12px;
        }

        // &.active {
        //     color: $theme-blue;
        // }

        a {
            font-weight: 400;
            font-size: 16px;
            color: $text-grey;
            transition: 0.5s ease;
            text-decoration: none;

            @media (max-width: 1366px) {
                font-size: 12px;
            }

            &:hover {
                color: $theme-blue;
            }
        }
    }
}