.notification-modal {
    .modal-dialog {
        margin-right: 2rem;

        @media (max-width: 767px) {
            margin-right: 0.5rem;
        }
    }
}

.modal-header {
    .btn-close {
        padding: 12px;
    }
}

.uploadModal,
.deleteModal,
.editModal {
    .modal-content {
        width: 460px;
        padding: 43px 30px 50px 38px;
        border-radius: 24px;
        border: 0;

        p {
            margin-top: 20px;
            margin-bottom: 41px;
            font-size: 20px;
            line-height: 29px;
            color: $text-grey;
        }
    }

}

//SelectDropdown
.modal-body {
    .rumSelect {
        &.normal {
            .rumSelect__placeholder {
                font-size: 14px;
                color: $text-grey;
            }
        }
    }
}

// *** Detail Modal */
.employeeDetail {
    p {
        &.columnHeading {
            font-size: 14px;
            line-height: 1;
            font-weight: bold;
        }
    }


    p {
        &.columnpara {
            font-size: 14px;
            display: inline-block;
            color: $theme-black;
            margin-bottom: 0;
            padding-right: 4px;
            line-height: 1;

            &.lineHeight-1p1 {
                line-height: 1.2;
            }
        }
    }

}


// *** Upload Modal */
.uploadPopup {
    .uploaded-file-container {
        h4 {
            font-size: 14px;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0em;
        }

        .uploading-wrapper {
            .icon-wrapper {
                position: relative;

                svg {
                    position: absolute;
                    right: 10px;
                    top: 14px;
                    cursor: pointer;
                }
            }
        }

        .uploaded-wrapper {
            .icon-wrapper {
                position: relative;

                svg {
                    position: absolute;
                    right: 10px;
                    top: 9px;
                    cursor: pointer;
                }
            }
        }

    }

    .glowBtn {
        &:disabled {
            background: $text-grey;
            border: none;
            box-shadow: none;
        }
    }
}


// Add Calender Range

.customRangeCalender {
    .rs-picker {
        box-shadow: 2px -3px 12px 10px rgb(196 203 214 / 10%);
        border-radius: 10px;
        border: 1px solid $text-grey;
        overflow: hidden;
    }

    .modal-content {
        width: 505px;
    }

    .dateRangeBox--holder {
        display: flex;
        justify-content: center;

        .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
        .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
            top: 8px !important;
        }
    }

}